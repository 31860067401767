import React from "react";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
import SEO from "../components/seo";

const aboutUs = () => (
    <Layout>
        <SEO title="Tentang kami" />
        <section id={"tentang-kami"} className={"section-bg tentang-kami"}>
            <Container>
                <div className={"section-title"}>
                    <h2>Tentang kami</h2>
                    <h3>Siapa kami</h3>
                    <Row>
                        <Col>
                            <p className={"text-justified"}>
                                CV. Anugerah Terang Dunia merupakan perusahaan
                                yang bergerak di bidang multimedia, yang
                                didirikan pada tahun 2016, selalu memberikan
                                pelayanan yang terbaik secara aktif, kreatif dan
                                inovatif untuk memenuhi kebutuhan klien maupun
                                mitra usaha kami. Dengan visi kami “Providing
                                the Best Solution”, kami telah mempersiapkan
                                kualitas terbaik dan kinerja yang handal.
                            </p>
                            <br />
                            <p className={"text-justified"}>
                                CV. Anugerah Terang Dunia menyediakan jasa dan
                                produk periklanan <i>(advertising)</i> berbentuk
                                LED Display Screen <i>(videotron)</i> yang dapat
                                digunakan di dalam ruangan <i>(indoor)</i> baik
                                untuk rapat, hingga di luar ruangan{" "}
                                <i>(outdoor)</i> seperti stadion, panggung,
                                rental, dll. Seiring dengan inovasi yang
                                terus-menerus dan teknologi semakin canggih,
                                kami selalu mengikuti perkembangannya guna untuk
                                memenuhi kebutuhan klien kami diantaranya Polda
                                Kalimantan Utara, Pemkot Kabupaten Blora, Gereja
                                Bethany, Cafe The Vin&apos;s Jakarta dan masih
                                banyak lainnya
                            </p>
                            <br />
                            <p className={"text-justified"}>
                                Sudah banyak produk layar LED buatan China yang
                                telah kami gunakan, sehingga kami telah
                                menemukan supplier LED dengan kualitas terbaik
                                di China dengan harga terjangkau. Produk yang
                                kami gunakan telah memenuhi Sistem Manajemen
                                Mutu <strong>ISO 9001:2015</strong> dan Sistem
                                Manajemen Lingkungan{" "}
                                <strong>ISO 14001:2015</strong> oleh CQM (
                                <i>China Quality mark Certification Group</i>)
                                dan juga telah bersertifikasi{" "}
                                <strong>CE</strong>, <strong>FCC</strong>,{" "}
                                <strong>CCC</strong>, <strong>RoHS</strong>
                            </p>
                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <Col>
                            <h3>Visi</h3>
                            <p>
                                Menjadi perusahaan yang dipercaya untuk
                                membangun dunia periklanan / multimedia di
                                Indonesia.
                            </p>
                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <Col>
                            <h3>Misi</h3>
                            <ol style={{ textAlign: "left" }}>
                                <li>
                                    Menyediakan produk LED yang berkualitas
                                    dengan memiliki standar dan sertifikasi ISO
                                    9001 & ISO 14001.
                                </li>
                                <li>
                                    Menyediakan jasa perakitan, perawatan
                                    (maintenance) dan asuransi produk LED untuk
                                    memenuhi kebutuhan klien maupun mitra usaha
                                    kami.
                                </li>
                                <li>
                                    Menyediakan tenaga ahli yang handal dan
                                    pelayanan terbaik dengan jangkauan seluruh
                                    Indonesia.
                                </li>
                            </ol>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    </Layout>
);

export default aboutUs;
